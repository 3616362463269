import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ChakraProvider } from '@chakra-ui/react';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import './i18n.js';

import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';

const root = ReactDOM.createRoot(document.getElementById('root'));

const clientProvider = new QueryClient();

root.render(
  <React.StrictMode>
    <ChakraProvider>
      <QueryClientProvider client={clientProvider}>
        <App />
        <ToastContainer />
      </QueryClientProvider>
    </ChakraProvider>
  </React.StrictMode>
);
