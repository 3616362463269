import React from 'react';
import { Select } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const Games = ({ value, setValue }) => {
  const {t} = useTranslation();
  const data = ['All', 'SP200', 'SP300', 'SP400', 'SP500'];

  return (
    <Select
      width={'full'}
      value={value}
      onChange={(e) => setValue(e.target.value)}>
      {data &&
        data?.length &&
        data.map((e, key) => (
          <option key={key} value={e}>
            {t(e)}
          </option>
        ))}
    </Select>
  );
};
export default Games;
