import request from '@request';
import { useMutation, useQueryClient } from 'react-query';

const putTicketStatus = ({ id }) => request.put(`/tickets/${id}/cancel`);

export const useMutateTicketStatus = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: putTicketStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tickets'] });
    }
  });
};
