import { Container, Flex } from '@chakra-ui/react';
import React from 'react';
import Navbar from './Navbar';

const MainLayout = ({ children }) => {
  return (
    <Container maxW="100%">
      <Navbar />

      <Flex h="100%">{children}</Flex>
    </Container>
  );
};

export default MainLayout;
