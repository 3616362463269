import React, { createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext(null);

export const useAuthContext = () => useContext(AuthContext);

export const Auth = ({ children }) => {
  const navigate = useNavigate();

  const login = (jwt, role) => {
    if (jwt) {
      localStorage.setItem('bo-token', jwt);
      localStorage.setItem('role', role);
      if (role === "finance")
        navigate('/rapports');
      else if (role === "support")
        navigate("/tickets");
      else
        navigate('/dashboard');
    }
  };

  const logout = () => {
    localStorage.removeItem('bo-token');
    localStorage.removeItem('role');
    navigate('/');
  };

  const isAuthorized = () => {
    const token = localStorage.getItem('bo-token');
    const role = localStorage.getItem('role');
    if (token && role) return true;
    else return false;
  };

  return (
    <AuthContext.Provider value={{ isAuthorized, login, logout }}>{children}</AuthContext.Provider>
  );
};
