import { useEffect, useState } from "react"
import { convertObjectToSearchParams } from '@utils/convertToSearchParams';
import { calculateTicketStats } from "../store/server/rapports/rapportsStats";
import axios from "axios";
import request from "@request";

export const useRapports = ({rapport, startDate, endDate, region, setIsLoading}) => {
	let data;
	const [res, setRes] = useState();

	const PvMensuel = async () => {
		return calculateTicketStats(data.data);
	}

	const PvAnnulation = async () => {
		const issuerStats = {};

		data.data.forEach(ticket => {
		  const issuerId = ticket.attributes.issuer?.data.id;
		  const status = ticket.attributes.status;
		  const stake = ticket.attributes.stake;
	  
		  // Check if the issuer ID exists in the stats object, if not, initialize it
		  if (!issuerStats[issuerId]) {
			issuerStats[issuerId] = {
			  issuerId: issuerId,
			  canceledTicketsCount: 0,
			  canceledTicketsStake: 0
			};
		  }
	  
		  // Update stats for canceled tickets
		  if (status === 'canceled') {
			issuerStats[issuerId].canceledTicketsCount++;
			issuerStats[issuerId].canceledTicketsStake += stake;
		  }
		});
	  
		// Convert issuerStats object to an array of objects
		const issuerStatsArray = Object.values(issuerStats);
		issuerStatsArray.push({
			issuerId: "Total",
			canceledTicketsCount: issuerStatsArray.reduce((acc, ticket) => {
					console.log(acc, ticket.canceledTicketsCount);
				  return acc + ticket.canceledTicketsCount;
			}, 0),
			canceledTicketsStake: issuerStatsArray.reduce((acc, ticket) => {
				return acc + ticket.canceledTicketsStake;
			}, 0)
		})
		return issuerStatsArray;
	}

	const PvVentes = async () => {
		const dateStats = {};
		let counter = 0;

		// Convert startDate and endDate to Date objects
		startDate = new Date("2024-05-01T10:50:39.239Z");
		endDate = new Date("2024-05-11T10:50:39.239Z");
	  
		console.log({startDate}, {endDate}, data.data);
		// Iterate through each ticket
		data.data.forEach((ticket, key) => {
		  const createdAt = new Date(ticket.attributes.createdAt).toISOString().split('T')[0];
	  
		  // Check if the createdAt date is within the specified range
		  if (createdAt >= startDate.toISOString().split('T')[0] && createdAt <= endDate.toISOString().split('T')[0]) {
			// Check if the date exists in the dateStats object, if not, initialize it
			if (!dateStats[createdAt]) {
			  dateStats[createdAt] = {
				day: ++counter,
				date: createdAt,
				totalStake: 0
			  };
			}
	  
			// Update total stake for the date
			dateStats[createdAt].totalStake += ticket.attributes.stake;
		  }
		});
	  
		// Convert dateStats object to an array of objects
		const dateStatsArray = Object.values(dateStats);
		dateStatsArray.push({
			day: "Total",
			totalStake: dateStatsArray.reduce((acc, stat) => {
				return acc + stat.totalStake;
			}, 0)
		})
		return dateStatsArray;
	}

	const setDataToRapport = async () => {
		switch (rapport) {
			case "PV Mensuel" :
				setRes(await PvMensuel());
				break ;
			case "PV Annulations":
				setRes(await PvAnnulation());
				break ;
			case "Recapulatif des ventes":
				setRes(await PvVentes());
				break ;
			default:
				console.log("Ce Choix ne correspant a aucun rapport");
		}
	}

	useEffect(() => {
		setIsLoading(false);
		const getRapports = async () => {
			// const searchParams = {
			// 	populate: 'paidBy,issuer',
			// 	'filters[createdAt][$gte]': startDate,
			// 	'filters[createdAt][$lte]': endDate,
			// 	'filters[selling_point][zone][city][region][id][$eq]': region,
			// 	'sort[createdAt]': 'DESC'
			// };

			try {
				// const response = await request.get(`/tickets?${convertObjectToSearchParams(searchParams)}`);
				const response = await axios.get("http://localhost:3002/rapportpv");
				data = response.data;
				setDataToRapport();
			} catch (error) {
				console.log("Error fetching data !");
				setIsLoading(true);
			}
		}
		getRapports();
	}, [rapport]);

	return res;
}
