export const convertObjectToSearchParams = (obj) => {
  // remove undefined values without mutating the original object
  const searchParamsObj = Object.keys(obj).reduce((acc, key) => {
    if (obj[key] && obj[key] !== undefined && obj[key] !== null) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});

  const searchParams = new URLSearchParams(searchParamsObj).toString();
  return searchParams;
};
