import React from 'react';
import { useAuthContext } from '@context/Auth';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children, type }) => {
  const { isAuthorized } = useAuthContext();
  const role = localStorage.getItem('role');
  const { pathname } = useLocation();

  console.log(role, pathname);

  if (type === 'protected' && !isAuthorized()) return <Navigate to="/" replace />;
  if (type === 'public' && isAuthorized()) return <Navigate to="/dashboard" replace />;
  if ((pathname === "/dashboard" || pathname === "/tickets" || pathname === "/agents") && role === "finance") return <Navigate to="/rapports" replace/>;
  if ((pathname === "/dashboard" || pathname === "/rapport" || pathname === "/agents") && role === "support") return <Navigate to="/tickets" replace/>;

  return children;
};

export default ProtectedRoute;
