import request from '@request';
import { useMutation } from 'react-query';

export const useMutateLogin = () => useMutation((data, role) => {
    // Include the role field in the data payload
    const requestData = { ...data, role };
    
    // Send a POST request to 'auth/local' with the updated data payload
    return request.post('auth/local', requestData);
});

