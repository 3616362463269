import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Text
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUpsertAgent } from '@store/server/agents/mutations';
import { IoIosArrowBack } from 'react-icons/io';
import { useAtom } from 'jotai';
import { agentFunctionnality } from '@store/client/agent';
import Alert from '@components/Alert';
import { useNavigate } from 'react-router-dom';

const UpsertAgent = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm();
  const { mutate, isLoading, isError, error } = useUpsertAgent();
  const [{ data }, setAgentFunctionnality] = useAtom(agentFunctionnality);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      reset({
        ...data,
        isAbleToChangePassword: data.isAbleToChangePassword?.toString(),
        confirmed: data.confirmed?.toString(),
        blocked: data.blocked?.toString()
      });
    }
  }, []);

  const onSubmit = (dt) => {
    mutate({
      ...dt,
      id: data ? data.id : undefined
    });
  };

  const onGoBack = () => {
    navigate('/agents');
    setAgentFunctionnality({ data: null });
  };

  return (
    <Grid gap="10px" width="100%" p={3}>
      <Flex justifyContent="flex-start" maxW="md">
        <Button
          variant="ghost"
          colorScheme="telegram"
          leftIcon={<IoIosArrowBack />}
          onClick={onGoBack}>
          Go back
        </Button>
      </Flex>
      <Alert isError={isError} error={error} />
      <Box p={3}>
        <Box border="1px" borderColor="gray.300" borderRadius="2xl" p={5}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SimpleGrid columns={[3]} gap="4">
              <FormControl isInvalid={errors?.email}>
                <FormLabel>E-mail</FormLabel>
                <Input
                  type="email"
                  placeholder="E-mail"
                  {...register('email', { required: t('errors.emailRequired') })}
                />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.email}>
                <FormLabel>username</FormLabel>
                <Input
                  type="text"
                  placeholder="username"
                  {...register('username', { required: t('errors.usernameRequired') })}
                />
                <FormErrorMessage>{errors.username?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.codeAgent}>
                <FormLabel>Code agent</FormLabel>
                <Input
                  type="text"
                  placeholder="Code agent"
                  {...register('codeAgent', { required: t('errors.codeAgentRequired') })}
                />
                <FormErrorMessage>{errors.codeAgent?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.agentType}>
                <FormLabel>{t('agentType')}</FormLabel>
                <Select
                  placeholder={t('agentType')}
                  {...register('agentType', { required: t('errors.agentTypeRequired') })}>
                  <option value="kiosk">KIOSK</option>
                  <option value="in agency">IN AGENCY</option>
                </Select>
                <FormErrorMessage>{errors.agentType?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.codePDV}>
                <FormLabel>Code PDV</FormLabel>
                <Select
                  placeholder="Code pdv"
                  {...register('codePDV', { required: t('errors.codePdvRequired') })}>
                  <option value="bet_4065">BET_4065</option>
                  <option value="bet_4056">BET_4056</option>
                </Select>
                <FormErrorMessage>{errors.codePDV?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.firstName}>
                <FormLabel>{t('firstName')}</FormLabel>
                <Input
                  type="text"
                  placeholder={t('firstName')}
                  {...register('firstName', { required: t('errors.firstNameRequired') })}
                />
                <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.lastName}>
                <FormLabel>{t('lastName')}</FormLabel>
                <Input
                  type="text"
                  placeholder={t('lastName')}
                  {...register('lastName', { required: t('errors.lastNameRequired') })}
                />
                <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.cni}>
                <FormLabel>CNI</FormLabel>
                <Input type="text" placeholder="CNI" {...register('cni')} />
                <FormErrorMessage>{errors.cni?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.supervisor}>
                <FormLabel>Supervisor</FormLabel>
                <Select
                  placeholder="Supervisor"
                  {...register('supervisor', { required: t('errors.supervisorRequired') })}>
                  <option value="angie baker">Angie Baker</option>
                  <option value="sanstos funk">Santos Funk</option>
                  <option value="jennie kuhlman">Jennie Kuhlman</option>
                </Select>
                <FormErrorMessage>{errors?.supervisor?.message}</FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel>Status</FormLabel>
                <Select placeholder="Status" {...register('status')}>
                  <option value="active">ACTIVE</option>
                  <option value="inactive">INACTIVE</option>
                  <option value="suspended">SUSPENDED</option>
                  <option value="in holiday">IN HOLIDAY</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>{t('maritalStatus')}</FormLabel>
                <Select placeholder={t('maritalStatus')} {...register('maritalStatus')}>
                  <option value="single">{t('maritalStatusKeys.single')}</option>
                  <option value="in relationship">{t('maritalStatusKeys.inRelationship')}</option>
                  <option value="married">{t('maritalStatusKeys.married')}</option>
                  <option value="divorced">{t('maritalStatusKeys.divorced')}</option>
                  <option value="widowed">{t('maritalStatusKeys.widowed')}</option>
                </Select>
              </FormControl>
              <FormControl isInvalid={errors?.phoneNumber}>
                <FormLabel>{t('phoneNumber')}</FormLabel>
                <Input
                  type="tel"
                  placeholder={t('phoneNumber')}
                  {...register('phoneNumber', { required: t('errors.phoneNumberRequired') })}
                />
                <FormErrorMessage>{errors?.phoneNumber?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.dataOfBirth}>
                <FormLabel>{t('dateOfBirth')}</FormLabel>
                <Input
                  type="date"
                  placeholder="Date de naissance"
                  {...register('dateOfBirth', { required: t('errors.dateOfBirthRequired') })}
                />
              </FormControl>
              <FormControl>
                <FormLabel>{t('startDate')}</FormLabel>
                <Input type="date" placeholder={t('startDate')} {...register('startDate')} />
              </FormControl>
              <FormControl>
                <FormLabel>{t('signin.password')}</FormLabel>
                <Input
                  type="password"
                  placeholder={t('signin.password')}
                  {...register('password', {
                    required: !data ? t('errors.passwordRequired') : false
                  })}
                />
                <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel>Terminal</FormLabel>
                <Input type="text" placeholder="Terminal" {...register('imei')} />
              </FormControl>
              <GridItem my={6} colSpan={3}>
                <Divider />
              </GridItem>
              <Grid gap="6px">
                <Text fontWeight={400}>{t('isAbleToChangePassword')}</Text>
                <Stack spacing={4} direction="row">
                  <Flex gap={2}>
                    <input type="radio" value="true" {...register('isAbleToChangePassword')} />
                    <label>{t('yes')}</label>
                  </Flex>
                  <Flex gap={2}>
                    <input type="radio" value="false" {...register('isAbleToChangePassword')} />
                    <label>{t('no')}</label>
                  </Flex>
                </Stack>
              </Grid>
              <Grid gap="6px">
                <Text fontWeight={400}>{t('confirmed')} ?</Text>
                <Stack spacing={4} direction="row">
                  <Flex gap={2}>
                    <input type="radio" value="true" {...register('confirmed')} />
                    <label>{t('yes')}</label>
                  </Flex>
                  <Flex gap={2}>
                    <input type="radio" value="false" {...register('confirmed')} />
                    <label>{t('no')}</label>
                  </Flex>
                </Stack>
              </Grid>
              <GridItem>
                <Grid gap="6px">
                  <Text fontWeight={400}>{t('blocked')} ?</Text>
                  <Stack spacing={4} direction="row">
                    <Flex gap={2}>
                      <input type="radio" value="true" {...register('blocked')} />
                      <label>{t('yes')}</label>
                    </Flex>
                    <Flex gap={2}>
                      <input type="radio" value="false" {...register('blocked')} />
                      <label>{t('no')}</label>
                    </Flex>
                  </Stack>
                </Grid>
              </GridItem>
              <GridItem my={6} colSpan={3}>
                <Divider />
              </GridItem>
              <GridItem colSpan={3}>
                <Flex justifyContent="flex-end">
                  <Button type="submit" isLoading={isLoading}>
                    Save
                  </Button>
                </Flex>
              </GridItem>
            </SimpleGrid>
          </form>
        </Box>
      </Box>
    </Grid>
  );
};

export default UpsertAgent;
