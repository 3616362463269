function startOfDay(date) {
  const startOfDay = new Date(date);
  return startOfDay.setUTCHours(0, 0, 0, 0);
}

function endOfDay(date) {
  const endOfDay = new Date(date);
  return endOfDay.setUTCHours(23, 59, 59, 999);
}

export { endOfDay, startOfDay };
