import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { Badge } from '@chakra-ui/react';

export function getStatus(status) {
  switch (status) {
    case 'lost':
      return <Badge colorScheme="red">LOST</Badge>;
    case 'win':
      return <Badge colorScheme="green">WIN</Badge>;
    case 'cancelled':
      return <Badge variant="outline">CANCELED</Badge>;
    default:
      return '-';
  }
}

export async function transformTicketResponse(allDataFinal) {
  return allDataFinal.map((ticket) => {
    return {
      id: ticket.id,
      uid: ticket.attributes.uid.split('-')[0],

      type: ticket.attributes.type,
      bets: ticket.attributes.bets.join(' - '),
      status:
        ticket.attributes.type === 'table'
          ? getStatus(ticket.attributes.status)
          : ticket.attributes.status,
      win: ticket.attributes.win,
      paidBy: ticket.attributes.paidBy?.data?.attributes.username,
      paidAt: ticket.attributes.paidAt
        ? moment(ticket.attributes.paidAt).format('DD MMM YYYY h:mm a')
        : '-',
      createdAt: moment(ticket.attributes.createdAt).format('DD MMM YYYY h:mm a'),
      updatedAt: moment(ticket.attributes.updatedAt).format('DD MMM YYYY h:mm a')
    };
  });
}
