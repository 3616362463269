import axios from 'axios';

const BASEURL = process.env.API_URL;

const request = axios.create({
  baseURL: BASEURL
});

request.interceptors.request.use(function (config) {
  const token = localStorage.getItem('bo-token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

export default request;
