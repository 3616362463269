import React from 'react';
import { Select } from '@chakra-ui/react';

const AsyncSelect = ({ data, value, setValue }) => {
  return (
    <Select
      width={"full"}
      value={value}
      placeholder="Select option"
      onChange={(e) => setValue(e.target.value)}>
      {data &&
        data?.length &&
        data.map((e) => (
          <option key={e.value} value={e.value}>
            {e.label}
          </option>
        ))}
    </Select>
  );
};
export default AsyncSelect;
