import React from 'react';
import AsyncSelect from './AsyncSelect';
import { useZones } from '../store/locations';

const Zones = ({ value, setValue, city, region }) => {
  const { data } = useZones({ city, region });

  return (
    <AsyncSelect
      value={value}
      setValue={setValue}
      data={
        data?.data?.data.map((item) => ({
          label: item.attributes.name,
          value: item.id
        })) || []
      }
    />
  );
};
export default Zones;
