import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl,
  Grid,
  Input,
  List,
  ListItem,
  Badge
} from '@chakra-ui/react';


import React, { useState } from 'react';
import { useTickets } from '@store/server/tickets/queries';
import DataGrid from '@components/DataGrid';
import moment from 'moment';
import { ImCancelCircle } from 'react-icons/im';
import { useDebounce } from '@uidotdev/usehooks';
import { CSVDownload } from 'react-csv';
import { useTranslation } from 'react-i18next';
import Pagination from '@components/Pagination';
import { useMutateTicketStatus } from '@store/server/tickets/mutations';
import Alert from '@components/Alert';

import Zones from '@components/Zones';
import Regions from '@components/Regions';
import Cities from '@components/Cities';

import DownloadCSVButton from '../components/ExportButton';
import { getStatus, transformTicketResponse } from '@utils/transformData';
import { convertObjectToSearchParams } from '@utils/convertToSearchParams';
import { endOfDay, startOfDay } from '../utils/date';

export function formatData(type, data) {
  const { mutate } = useMutateTicketStatus();
  const role = localStorage.getItem('role');
  const { t } = useTranslation();

  if (data)
    return data?.data?.data.map((item) => {
      var betsGrouped = item.attributes.bets
        .map((bet) => {
          return bet.split(',').reduce((curr, acc) => {
            return (curr += Number(acc));
          }, 0);
        })
        .reduce((curr, acc) => (curr += `${acc} `), '');
      return {
        ...item.attributes,
        bets:
          type === 'table' ? (
            <Accordion allowToggle>
              <AccordionItem border="none">
                <h2>
                  <AccordionButton _expanded={{ bg: 'pink', color: 'white' }}>
                    <Box as="span" flex="1" textAlign="left">
                      {betsGrouped}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel>
                  <List spacing={2}>
                    {item.attributes.bets.map((bet, index) => (
                      <ListItem key={index}>{bet.replace(/,/g, '-')}</ListItem>
                    ))}
                  </List>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          ) : (
            betsGrouped
          ),
        uid: item.attributes.uid.split('-')[0],
        status: type === 'table' ? getStatus(item.attributes.status) : item.attributes.status,
        createdAt: moment(item.attributes.createdAt).format('DD MMM YYYY h:mm a'),
        updatedAt: moment(item.attributes.updatedAt).format('DD MMM YYYY h:mm a'),
        paidBy: item.attributes.paidBy.data ? item.attributes.paidBy.data.attributes.username : '-',
        paidAt: item.attributes.paidAt
          ? moment(item.attributes.paidAt).format('DD MMM YYYY h:mm a')
          : '-',
        soldBy: item.attributes.issuer.data.attributes.firstName + " " + item.attributes.issuer.data.attributes.lastName,
        printed: item.attributes.printed ? (
          <Badge colorScheme="green" variant="outline">
            {t('printed')}
          </Badge>
        ) : (
          <Badge colorScheme="red" variant="outline">
            {t('not printed')}
          </Badge>
        ),
        action:
          type === 'table' ? (
            item.attributes.status === 'cancelled' ? (
              <Button isDisabled colorScheme="gray">
                Cancelled
              </Button>
            ) : (
              <Flex hidden={role !== "support" ? false : true}>
                <Button
                  colorScheme="gray"
                  variant="outline"
                  leftIcon={<ImCancelCircle />}
                  onClick={() => mutate({ id: item.id })}>
                  Cancel
                </Button>
              </Flex>
            )
          ) : (
            ''
          )
      };
    });
  return [];
}

const Tickets = () => {
  const [search, setSearch] = useState('');
  const [agent, setAgent] = useState();
  const [zone, setZone] = useState();
  const [city, setCity] = useState();
  const [region, setRegion] = useState();
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [page, setPage] = useState(1);
  const searchDebounced = useDebounce(search, 500);
  const { data, isSuccess, isLoading, isError, error } = useTickets({
    agent: agent,
    search: searchDebounced,
    endDate: endDate,
    startDate: startDate,
    city,
    zone,
    region,
    page
  });

  if (data)
    console.log(data.data.data);

  const { t } = useTranslation();

  const columns = [
    {
      key: 'uid',
      label: 'uid'
    },
    {
      key: 'type',
      label: 'Type'
    },
    {
      key: 'bets',
      label: t('bets')
    },
    {
      key: 'status',
      label: 'Status'
    },
    {
      key: 'win',
      label: t('win')
    },
    {
      key: 'paidBy',
      label: t('paidBy')
    },
    {
      key: 'paidAt',
      label: t('paidAt')
    },
    {
      key: 'soldBy',
      label: 'vendu par'
    },
    {
      key: 'createdAt',
      label: t('createdAt')
    },
    {
      key: 'updatedAt',
      label: t('updatedAt')
    },{
      key: 'printed',
      label: t('printed')
    },
    {
      key: 'action',
      label: ''
    } 
  ];

  const baseUrl = `${process.env.API_URL}`;

  return (
    <Box p={3} w="100%" overflow="auto">
      <Flex my="5" justifyContent="space-between">
        <Input
          name="search"
          type="text"
          placeholder={t('searchByUid')}
          maxW="sm"
          onChange={(e) => {
            setPage(1);
            setSearch(e.target.value.toUpperCase());
          }}
        />
        <DownloadCSVButton
          options={{
            path: '/tickets',
            fetchParams: ({ pagination }) => {
              const searchParams = {
                populate: 'paidBy,issuer',
                'filters[uid][$startsWith]': searchDebounced,
                'pagination[page]': pagination.page,
                'pagination[pageSize]': pagination.pageSize,
                'filters[createdAt][$gte]': startDate,
                'filters[createdAt][$lte]': endDate,
                'filters[issuer][username][$contains]': agent,
                'filters[selling_point][zone][city][id][$eq]': city,
                'filters[selling_point][zone][id][$eq]': zone,
                'filters[selling_point][zone][city][region][id][$eq]': region,
                'sort[createdAt]': 'DESC'
              };
              return `/tickets?${convertObjectToSearchParams(searchParams)}`;
            },
            transformResponse: transformTicketResponse
          }}
          src={baseUrl}
          execDownload={({ data, ref }) => {
            return <CSVDownload data={data} ref={ref} target="self" />;
          }}
        />
      </Flex>
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        <FormControl>
          <h6>{t('Username')}</h6>
          <Input
            width='full'
            placeholder={t('Username')}
            name="search"
            onChange={(e) => setAgent(e.target.value)}
          />
        </FormControl>

        <FormControl>
          <h6>{t('from')}</h6>

          <Input
            width='full'
            type="date"
            placeholder="Start Date"
            onChange={(e) => setStartDate(new Date(startOfDay(e.target.value)).toISOString())}
          />
        </FormControl>
        <FormControl>
          <h6>{t('to')}</h6>

          <Input
            width='full'
            type="date"
            placeholder="End Date"
            onChange={(e) => setEndDate(new Date(endOfDay(e.target.value)).toISOString())}
          />
        </FormControl>

        <FormControl>
          <h6>{t('regions')}</h6>
          <Regions value={region} setValue={setRegion} />
        </FormControl>
        <FormControl>
          <h6>{t('cities')}</h6>
          <Cities value={city} setValue={setCity} region={region} />
        </FormControl>
        <FormControl>
          <h6>{t('zones')}</h6>
          <Zones value={zone} setValue={setZone} city={city} region={region} />
        </FormControl>
      </Grid>
      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          pageCount={data?.data?.meta.pagination.pageCount}
          onPrevious={() => setPage(page > 0 ? page - 1 : page)}
          onNext={() => setPage(page < data?.data?.meta.pagination.pageCount ? page + 1 : page)}
          onPageChange={(page) => setPage(page)}
        />
      </Flex>
      <Alert isError={isError} error={error} />
      <DataGrid
        isLoading={isLoading}
        columns={columns}
        rows={formatData('table', !isLoading && isSuccess ? data : null)}
      />
    </Box>
  );
};

export default Tickets;
