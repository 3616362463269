import {
  Box,
  Button,
  Flex,
  FormControl,
  Grid,
  Input,
  Heading
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Regions from '@components/Regions';
import { TfiExport } from 'react-icons/tfi';
import { CSVLink } from 'react-csv';
import TypePv from "@components/TypePv";
import { startOfDay, endOfDay } from '../../utils/date';
import DataGrid from '@components/DataGrid';
import { useRapports } from '../../hooks/useRapports';
import { useTickets } from '@store/server/tickets/queries';
import Paid from '../../components/Paid';
import Games from '../../components/Games';
import Pagination from '@components/Pagination';
import { ticketsWon } from '../../store/server/rapports/ticketsWon';
import { useSales } from '../../hooks/useSales';

function filterSales(data, game) {
  const result = [];

  if (data) {
    for (let i = 0; i < data.length; i++) {
      if (game === "All" || (game !== "All" && data[i].game === game))
        result.push(data[i]);
    }
  }
  return result;
}

const Proces_Verbal = () => {
  const [region, setRegion] = useState();
  // const [zone, setZone] = useState();
  // const [city, setCity] = useState();
  // const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  // const [agent, setAgent] = useState();
  const [rapport, setRapport] = useState("PV Mensuel");
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const data = useRapports({
    rapport: rapport,
    startDate: startDate,
    endDate: endDate,
    setIsLoading,
  });
  // const { data, isSuccess, isLoading, isError, error } = useTickets({
  //   agent: agent,
  //   endDate: endDate,
  //   startDate: startDate,
  //   city,
  //   zone,
  //   region,
  //   page
  // });


  const { t } = useTranslation();

  const [columns, setColumns] = useState([
    {
      key: 'type',
      label: 'Type'
    },
    {
      key: 'totalStakes',
      label: t('Stakes')
    },
    {
      key: 'numTickets',
      label: t('TicketsSold')
    },
    {
      key: 'numTicketsWon',
      label: t('TicketsWon')
    },
    {
      key: 'totalMoneyWon',
      label: t('Profit')
    }
  ]);

  useEffect(() => {
    if (rapport === "Recapulatif des ventes") {
      setColumns([
        {
          key: 'day',
          label: t('Day')
        },
        {
          key: 'date',
          label: t('Date')
        },
        {
          key: 'totalStake',
          label: t('Sales')
        }
      ]);
    }
    else if (rapport === "PV Annulations") {
      setColumns([
        {
          key: 'issuerId',
          label: t('Agent Code')
        },
        {
          key: 'canceledTicketsCount',
          label: t('TicketsCanceled')
        },
        {
          key: 'canceledTicketsStake',
          label: t('Stakes')
        }
      ]);
    }
    else {
      setColumns([
        {
          key: 'type',
          label: 'Type'
        },
        {
          key: 'totalStakes',
          label: t('Stakes')
        },
        {
          key: 'numTickets',
          label: t('TicketsSold')
        },
        {
          key: 'numTicketsWon',
          label: t('TicketsWon')
        },
        {
          key: 'totalMoneyWon',
          label: t('Profit')
        }
      ]);
    }
  }, [rapport])

  return (
    <Box p={3} w="100%" overflow="auto" display={"flex"} flexFlow={"column"} gap={"40px"} >
      <Box p={3} w={"100%"} overflow="auto">
        <Heading textAlign={"center"}>Proces Verbaux</Heading>
        <Flex my={5} alignItems={"end"} justifyContent={"space-between"}>
          <Box w="100%" display={"flex"} flexFlow={"column"} gap={"40px"}>
            <FormControl>
              <h6>Rapports</h6>
              <TypePv value={rapport} setValue={setRapport} data={['PV Mensuel', 'PV Annulations', 'Recapulatif des ventes']} />
            </FormControl>
            {rapport === "PV Annulations" && (
              <Input
                name="search"
                type="text"
                placeholder={t('searchBySeller')}
                maxW="sm"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            )}
          </Box>
          {data && (
            <Button size="sm" leftIcon={<TfiExport />} colorScheme="pink" variant="outline">
              <CSVLink
                filename="rapportsPv.csv"
                headers={columns}
                data={data}>
                {t('export')}
              </CSVLink>
            </Button>
          )}
        </Flex>
        <Grid templateColumns="repeat(3, 1fr)" gap={6}>
          <FormControl>
            <h6>{t('from')}</h6>

            <Input
              width='full'
              type="date"
              placeholder="Start Date"
              onChange={(e) => setStartDate(new Date(startOfDay(e.target.value)).toISOString())}
            />
          </FormControl>
          <FormControl>
            <h6>{t('to')}</h6>

            <Input
              width='full'
              type="date"
              placeholder="End Date"
              onChange={(e) => setEndDate(new Date(endOfDay(e.target.value)).toISOString())}
            />
          </FormControl>

          <FormControl>
            <h6>{t('regions')}</h6>
            <Regions value={region} setValue={setRegion} />
          </FormControl>
        </Grid>
      </Box>
      <DataGrid
        isLoading={isLoading}
        columns={columns}
        rows={data}
      />
    </Box>
  );
}

const TicketsWon = () => {
  const [search, setSearch] = useState('');
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [paid, setPaid] = useState("All");
  const [region, setRegion] = useState();
  const [page, setPage] = useState(1);
  const {t} = useTranslation();
  const { data, isSuccess, isLoading, isError, error } = useTickets({
    endDate: endDate,
    startDate: startDate,
    region,
    page
  });

  useEffect(() => {

  }, [paid]);

  const columns = [
    {
      key: 'id',
      label: 'Ticket'
    },
    {
      key: 'uid',
      label: t('Seller')
    },
    {
      key: 'status',
      label: 'status'
    },
    {
      key: 'date',
      label: 'date'
    },
    {
      key: 'profit',
      label: t('Profit')
    }
  ];

  return (
    <Box p={3} w="100%" overflow="auto" display={"flex"} flexFlow={"column"} gap={"40px"}>
      <Box p={3} w={"100%"} overflow="auto">
        <Heading textAlign={"center"}>{t('WinningTickets')}</Heading>
        <Flex my={5} alignItems={"end"} justifyContent={"space-between"}>
          <Input
            name="search"
            type="text"
            placeholder={t('searchBySeller')}
            maxW="sm"
            onChange={(e) => {
              setPage(1);
              setSearch(e.target.value.toUpperCase());
            }}
          />
          {data && data.data && (
            <Button size="sm" leftIcon={<TfiExport />} colorScheme="pink" variant="outline">
              <CSVLink
                filename="rapportsPv.csv"
                headers={columns}
                data={data.data.data}>
                {t('export')}
              </CSVLink>
            </Button>
          )}
        </Flex>
        <Grid templateColumns="repeat(3, 1fr)" gap={6}>
          <FormControl>
            <h6>{t('from')}</h6>

            <Input
              width='full'
              type="date"
              placeholder="Start Date"
              onChange={(e) => setStartDate(new Date(startOfDay(e.target.value)).toISOString())}
            />
          </FormControl>
          <FormControl>
            <h6>{t('to')}</h6>

            <Input
              width='full'
              type="date"
              placeholder="End Date"
              onChange={(e) => setEndDate(new Date(endOfDay(e.target.value)).toISOString())}
            />
          </FormControl>

          <FormControl>
            <h6>{t('regions')}</h6>
            <Regions value={region} setValue={setRegion} />
          </FormControl>
          <FormControl>
            <h6>{t('Status')}</h6>
            <Paid value={paid} setValue={setPaid} />
          </FormControl>
        </Grid>
      </Box>
      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          pageCount={data?.data?.meta.pagination.pageCount}
          onPrevious={() => setPage(page > 0 ? page - 1 : page)}
          onNext={() => setPage(page < data?.data?.meta.pagination.pageCount ? page + 1 : page)}
          onPageChange={(page) => setPage(page)}
        />
      </Flex>
      {data && data.data && (
        <DataGrid
          isLoading={isLoading}
          columns={columns}
          rows={ticketsWon(data.data.data, paid, t, search)}
        />
      )}
    </Box>
  )
}

const Sales = () => {
  const {t} = useTranslation();
  const [region, setRegion] = useState();
  const [sales, setSales] = useState("Ventes");
  const [game, setGame] = useState("All");
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const data = useSales({
    rapport: sales,
    startDate: startDate,
    endDate: endDate,
    setIsLoading,
  });

  const [columns, setColumns] = useState([
    {
      key: 'issuerId',
      label: t('Agent Code')
    },
    {
      key: 'game',
      label: t('Game')
    },
    {
      key: 'numTickets',
      label: t('TicketsSold')
    },
    {
      key: 'sales',
      label: t('Stakes')
    }
  ]);

  useEffect(() => {

  }, [game]);

  useEffect(() => {
    if (sales === "Meilleurs Vendeurs") {
      setColumns([
        {
          key: 'issuerId',
          label: t('Agent Code')
        },
        {
          key: 'numTickets',
          label: t('TicketsSold')
        },
        {
          key: 'sales',
          label: t('Stakes')
        }
      ]);
    }
    else {
      setColumns([
        {
          key: 'issuerId',
          label: t('Agent Code')
        },
        {
          key: 'game',
          label: t('Game')
        },
        {
          key: 'numTickets',
          label: t('TicketsSold')
        },
        {
          key: 'sales',
          label: t('Stakes')
        }
      ]);
    }
  }, [sales]);

  return (
    <Box p={3} w="100%" overflow="auto" display={"flex"} flexFlow={"column"} gap={"40px"} >
      <Box p={3} w={"100%"} overflow="auto">
        <Heading textAlign={"center"}>{t('Sales')}</Heading>
        <Flex my={5} alignItems={"end"} justifyItems={"space-between"}>
          <FormControl>
            <h6>{t('Sales')}</h6>
            <TypePv value={sales} setValue={setSales} data={["Ventes", "Meilleurs Vendeurs"]}/>
          </FormControl>
          {data && (
            <Button size="sm" leftIcon={<TfiExport />} colorScheme="pink" variant="outline">
              <CSVLink
                filename="rapportsPv.csv"
                headers={columns}
                data={data}>
                {t('export')}
              </CSVLink>
            </Button>
          )}
        </Flex>
        <Grid templateColumns="repeat(3, 1fr)" gap={6}>
          <FormControl>
            <h6>{t('from')}</h6>

            <Input
              width='full'
              type="date"
              placeholder="Start Date"
              onChange={(e) => setStartDate(new Date(startOfDay(e.target.value)).toISOString())}
            />
          </FormControl>
          <FormControl>
            <h6>{t('to')}</h6>

            <Input
              width='full'
              type="date"
              placeholder="End Date"
              onChange={(e) => setEndDate(new Date(endOfDay(e.target.value)).toISOString())}
            />
          </FormControl>

          <FormControl>
            <h6>{t('regions')}</h6>
            <Regions value={region} setValue={setRegion} />
          </FormControl>
          <FormControl>
            <h6>{t('Game')}</h6>
            <Games value={game} setValue={setGame} />
          </FormControl>
        </Grid>
      </Box>
      <DataGrid
        isLoading={isLoading}
        columns={columns}
        rows={filterSales(data, game)}
      />
    </Box>
  );
}

const Rapports = () => {
  return (
    <Box w={"100%"} display={"flex"} flexDirection={"column"} gap={"20px"} py={"10px"}>
      <Proces_Verbal />
      <TicketsWon />
      <Sales />
    </Box>
  );
}

export default Rapports;