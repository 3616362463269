import moment from "moment";
import { useTranslation } from "react-i18next";

export function ticketsWon(data, paid, t, search) {
	const tickets = [];

	data.map((ticket) => {
		if (ticket.attributes.status === 'win') {
			const TicketId = ticket.id;
			const TicketSeller = ticket.attributes.issuer.data.attributes.firstName + " " + ticket.attributes.issuer.data.attributes.lastName;
			const TicketStatus = (ticket.attributes.paid ? "paid" : "unpaid");
			const TicketDate = moment(ticket.attributes.createdAt).format('DD MMM YYYY h:mm a');
			const TicketProfit = ticket.attributes.win;

			if (paid === "All" || (paid !== "All" && TicketStatus === paid)) {
				tickets.push({
					id: TicketId,
					uid: TicketSeller,
					status: t(TicketStatus),
					date: TicketDate,
					profit: TicketProfit
				})
				if (search && !TicketSeller.toUpperCase().startsWith(search))
					tickets.pop();
			}
		}
	})
	return tickets;
}