import React from 'react';
import { Select } from '@chakra-ui/react';

const TypePv = ({ value, setValue, data }) => {
  return (
    <Select
      width={'33%'}
      value={value}
      onChange={(e) => setValue(e.target.value)}>
      {data &&
        data.map((e, key) => (
          <option key={key} value={e}>
            {e}
          </option>
        ))}
    </Select>
  );
};
export default TypePv;
