import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Input
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoPersonAdd } from 'react-icons/io5';
import { TfiExport } from 'react-icons/tfi';
import { useAgents } from '@store/server/agents/queries';
import Alert from '@components/Alert';
import DataGrid from '@components/DataGrid';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { useAtom } from 'jotai';
import { agentFunctionnality } from '@store/client/agent';
import { useDebounce } from '@uidotdev/usehooks';
import { AiOutlineEdit } from 'react-icons/ai';
import Pagination from '@components/Pagination';
import { useNavigate } from 'react-router-dom';

import Zones from '@components/Zones';
import Regions from '@components/Regions';
import Cities from '@components/Cities';
import request from '../../axios.config';
import { toast } from 'react-toastify';
import { endOfDay, startOfDay } from '../../utils/date';

const formatData = (type, data) => {
  const [, setAgentTypeFunctionnality] = useAtom(agentFunctionnality);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onEditClick = (agent) => {
    setAgentTypeFunctionnality({
      data: agent
    });
    navigate('/agents-upsert');
  };

  if (data)
    return data.data.data.map((agent) => ({
      ...agent,
      confirmed: agent.confirmed ? t('yes') : t('no'),
      blocked: agent.blocked ? t('yes') : t('no'),
      createdAt: moment(agent.createdAt).format('DD MMM YYYY h:mm a'),
      updatedAt: moment(agent.updatedAt).format('DD MMM YYYY h:mm a'),
      action:
        type === 'table' ? (
          <IconButton
            icon={<AiOutlineEdit />}
            colorScheme="linkedin"
            onClick={() => onEditClick(agent)}
          />
        ) : (
          ''
        )
    }));
  return [];
};

const Agents = () => {
  const [search, setSearch] = useState('');
  const [agent, setAgent] = useState('');
  const [zone, setZone] = useState();
  const [city, setCity] = useState();
  const [region, setRegion] = useState();
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [page, setPage] = useState(1);
  const searchDebounced = useDebounce(search, 800);
  const { data, isLoading, isSuccess, isError, error } = useAgents({
    agent: agent,
    search: searchDebounced,
    endDate: endDate,
    startDate: startDate,
    city,
    zone,
    region,
    page
  });
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columns = [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'username',
      label: 'Username'
    },
    {
      key: 'firstName',
      label: t('firstName')
    },
    {
      key: 'lastName',
      label: t('lastName')
    },
    {
      key: 'email',
      label: 'E-mail'
    },
    {
      key: 'phoneNumber',
      label: t('phoneNumber')
    },
    {
      key: 'imei',
      label: 'Imei'
    },
    {
      key: 'confirmed',
      label: t('confirmed')
    },
    {
      key: 'blocked',
      label: t('blocked')
    },
    {
      key: 'agentType',
      label: t('agentType')
    },
    {
      key: 'createdAt',
      label: t('createdAt')
    },
    {
      key: 'updatedAt',
      label: t('updatedAt')
    },
    {
      key: 'status',
      label: 'Status'
    },
    {
      key: 'action',
      label: ''
    }
  ];

  const onResetTerminal = () => {
    request
      .delete('/terminals')
      .then(() => toast.success(t('initTerminal')))
      .catch(() => toast.error('Error: Something went wrong'));
  };

  return (
    <Box display="grid" gap="16px" p={3} width="100%">
      <Flex justifyContent="space-between" alignItems="center">
        <Input
          placeholder={t('searchByAnything')}
          name="search"
          maxW="sm"
          onChange={(e) => setSearch(e.target.value)}
        />
        <Flex gap="6px">
          <Button
            leftIcon={<IoPersonAdd />}
            colorScheme="telegram"
            size="sm"
            onClick={() => navigate('/agents-upsert')}>
            {t('addAgent')}
          </Button>
          <Button size="sm" leftIcon={<TfiExport />} colorScheme="pink" variant="outline">
            <CSVLink
              filename="agents.csv"
              headers={columns}
              data={formatData('csv', !isLoading && isSuccess ? data : null)}>
              {t('export')}
            </CSVLink>
          </Button>
          <Button size="sm" colorScheme="red" onClick={onResetTerminal}>
            {t('resetTerminal')}
          </Button>
        </Flex>
      </Flex>
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        <FormControl>
          <h6>{t('Username')}</h6>
          <Input
            width='full'
            placeholder={t('Username')}
            name="search"
            onChange={(e) => setAgent(e.target.value)}
          />
        </FormControl>

        <FormControl>
          <h6>{t('from')}</h6>

          <Input
            width='full'
            type="date"
            placeholder="Start Date"
            onChange={(e) => setStartDate(new Date(startOfDay(e.target.value)).toISOString())}
          />
        </FormControl>
        <FormControl>
          <h6>{t('to')}</h6>

          <Input
            width='full'
            type="date"
            placeholder="End Date"
            onChange={(e) => setEndDate(new Date(endOfDay(e.target.value)).toISOString())}
          />
        </FormControl>
        <FormControl>
          <h6>{t('regions')}</h6>
          <Regions value={region} setValue={setRegion} />
        </FormControl>
        <FormControl>
          <h6>{t('cities')}</h6>
          <Cities value={city} setValue={setCity} region={region} />
        </FormControl>
        <FormControl>
          <h6>{t('zones')}</h6>
          <Zones value={zone} setValue={setZone} city={city} region={region} />
        </FormControl>
      </Grid>

      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          pageCount={data?.data?.meta.pagination.pageCount}
          onPrevious={() => setPage(page > 0 ? page - 1 : page)}
          onNext={() => setPage(page < data?.data?.meta.pagination.pageCount ? page + 1 : page)}
          onPageChange={(page) => setPage(page)}
        />
      </Flex>
      <Alert isError={isError} error={error} />
      <DataGrid
        columns={columns}
        rows={formatData('table', !isLoading && isSuccess ? data : null)}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default Agents;
