import { useEffect, useState } from "react"
import axios from "axios";


export const useSales = ({rapport, startDate, endDate, region, setIsLoading}) => {
	let data;
	const [res, setRes] = useState();

	async function calculateIssuerStats() {
		const issuerStats = {};
	  
		// Iterate over each ticket
		data.data.forEach(ticket => {
		  const issuerId = ticket.attributes.issuer.data.id;
		  const ticketType = ticket.attributes.type;
		  const ticketStake = ticket.attributes.stake;
	  
		  // Initialize issuer stats if not present
		  if (!issuerStats[issuerId]) {
			issuerStats[issuerId] = {};
		  }
	  
		  // Initialize ticket type stats for the issuer if not present
		  if (!issuerStats[issuerId][ticketType]) {
			issuerStats[issuerId][ticketType] = {
			  soldCount: 0,
			  totalStake: 0
			};
		  }
	  
		  // Increment sold count and add stake for the ticket type
		  issuerStats[issuerId][ticketType].soldCount++;
		  issuerStats[issuerId][ticketType].totalStake += ticketStake;
		});
	  
		// Convert issuerStats object into array of objects
		const result = [];
		for (const issuerId in issuerStats) {
		  for (const ticketType in issuerStats[issuerId]) {
			result.push({
			  issuerId: issuerId,
			  game: ticketType,
			  numTickets: issuerStats[issuerId][ticketType].soldCount,
			  sales: issuerStats[issuerId][ticketType].totalStake
			});
		  }
		}
		return result;
	  }
	  

	  async function bestSellers() {
		const issuerStats = {};

		// Iterate over each ticket
		data.data.forEach(ticket => {
		  const issuerId = ticket.attributes.issuer.data.id;
		  const ticketStake = ticket.attributes.stake;
	  
		  // Initialize issuer stats if not present
		  if (!issuerStats[issuerId]) {
			issuerStats[issuerId] = {
			  totalStakes: 0,
			  totalTicketsSold: 0
			};
		  }
	  
		  // Add ticket stake to issuer's total stakes
		  issuerStats[issuerId].totalStakes += ticketStake;
	  
		  // Increment total tickets sold for the issuer
		  issuerStats[issuerId].totalTicketsSold++;
		});
	  
		// Convert issuerStats object into array of objects
		const result = [];
		for (const issuerId in issuerStats) {
		  result.push({
			issuerId: issuerId,
			numTickets: issuerStats[issuerId].totalTicketsSold,
			sales: issuerStats[issuerId].totalStakes
		  });
		}
	  
		// Sort the result array in descending order based on total stakes
		result.sort((a, b) => b.sales - a.sales);
	  
		return result;
	  }
	  

	const setDataToRapport = async () => {
		switch (rapport) {
			case "Ventes" :
				setRes(await calculateIssuerStats());
				break ;
			case "Meilleurs Vendeurs":
				setRes(await bestSellers());
				break ;
			default:
				console.log("Ce Choix ne correspant a aucun rapport");
		}
	}

	useEffect(() => {
		setIsLoading(false);
		const getRapports = async () => {
			// const searchParams = {
			// 	populate: 'paidBy,issuer',
			// 	'filters[createdAt][$gte]': startDate,
			// 	'filters[createdAt][$lte]': endDate,
			// 	'filters[selling_point][zone][city][region][id][$eq]': region,
			// 	'sort[createdAt]': 'DESC'
			// };

			try {
				// const response = await request.get(process.env.API_URL + `/tickets?${convertObjectToSearchParams(searchParams)}`);
				const response = await axios.get("http://localhost:3002/rapportpv");
				data = response.data;
				console.log({data})
				setDataToRapport();
			} catch (error) {
				console.log("Error fetching data !");
				setIsLoading(true);
			}
		}
		getRapports();
	}, [rapport]);

	return res;
}
