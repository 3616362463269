import React from 'react';
import AsyncSelect from './AsyncSelect';
import { useRegions } from '../store/locations';

const Regions = ({ value, setValue }) => {
  const { data } = useRegions();

  return (
    <AsyncSelect
      value={value}
      setValue={setValue}
      data={
        data?.data?.data.map((item) => ({
          label: item.attributes.name,
          value: item.id
        })) || []
      }
    />
  );
};
export default Regions;
