import React from 'react';
import { Alert as AlertChakra, AlertDescription, AlertIcon, Flex } from '@chakra-ui/react';

const Alert = ({ isError, error }) => {
  if (isError)
    return (
      <Flex my="2">
        <AlertChakra status="error">
          <AlertIcon />
          <AlertDescription>
            {error.code === 'ERR_NETWORK' ? error.message : error?.response.data.error.message}
          </AlertDescription>
        </AlertChakra>
      </Flex>
    );
  return null;
};

export default Alert;
