import request from '@request';
import { convertObjectToSearchParams } from '@utils/convertToSearchParams';
import { useQuery } from 'react-query';

const getAgents = ({
  queryKey: [, { search, page, agent, endDate, startDate, city, zone, region }]
}) => {
  const searchParams = {
    'sort[createdAt]': 'DESC',
    'pagination[page]': page,
    'filters[createdAt][$gte]': startDate,
    'filters[createdAt][$lte]': endDate,
    'filters[username][$contains]': agent,
    'filters[selling_point][zone][city][id][$eq]': city,
    'filters[selling_point][zone][id][$eq]': zone,
    'filters[selling_point][zone][city][region][id][$eq]': region,
    _q: search
  };
  return request.get(`/agents?${convertObjectToSearchParams(searchParams)}`);
};

export const useAgents = ({ search, page, agent, endDate, startDate, city, zone, region }) =>
  useQuery(['agents', { search, page, agent, endDate, startDate, city, zone, region }], getAgents);
