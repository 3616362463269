import React from 'react';
import { Button, Flex, IconButton } from '@chakra-ui/react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { BsThreeDots } from 'react-icons/bs';
import { usePagination, DOTS } from '../hooks/usePagination';

const Pagination = ({ page, pageCount, onPrevious, onNext, onPageChange }) => {
  const range = usePagination({ totalPageCount: pageCount, currentPage: page });

  if (!pageCount || !range || !range.length) return null;

  return (
    <Flex gap={3} my={4} justify="center">
      <IconButton icon={<IoIosArrowBack />} isDisabled={page === 1} onClick={onPrevious} />
      {range.map((val, index) => {
        if (val === DOTS) return <IconButton icon={<BsThreeDots />} key={index} variant="link" />;
        else
          return (
            <Button
              variant="outline"
              key={index}
              isDisabled={val === page}
              onClick={() => onPageChange(val)}>
              {val}
            </Button>
          );
      })}
      <IconButton
        icon={<IoIosArrowForward />}
        isDisabled={page === pageCount || pageCount === 1}
        onClick={onNext}
      />
    </Flex>
  );
};

export default Pagination;
