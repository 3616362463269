export function calculateTicketStats(data) {
	const ticketTypes = ["SP200", "SP300", "SP400", "SP500"];
	const statsArray = [];
	let Money = 0, Stakes = 0, Tickets = 0, TicketsWon = 0;
  
	ticketTypes.forEach(type => {
	  const filteredTickets = data.filter(ticket => ticket.attributes.type === type);
	  const totalStakes = filteredTickets.reduce((acc, ticket) => acc + ticket.attributes.stake, 0);
	  const numTickets = filteredTickets.length;
	  const numTicketsWon = filteredTickets.filter(ticket => ticket.attributes.status === "win").length;
	  const totalMoneyWon = filteredTickets.reduce((acc, ticket) => {
		if (ticket.attributes.status === "win") {
		  return acc + ticket.attributes.win;
		} else {
		  return acc;
		}
	  }, 0);
	  Money += totalMoneyWon;
	  Stakes += totalStakes;
	  Tickets += numTickets;
	  TicketsWon += numTicketsWon;
  
	  statsArray.push({
		type,
		totalStakes,
		numTickets,
		numTicketsWon,
		totalMoneyWon
	  });
	});
	statsArray.push({
		type: "Total",
		totalStakes: Stakes,
		numTickets: Tickets,
		numTicketsWon: TicketsWon,
		totalMoneyWon: Money
	})
  
	return statsArray;
}
  